import type { MaybeRef } from 'vue'
import dayjs, { type Dayjs } from 'dayjs'
import { OutgoingYearType } from '../constants'
import type { Outgoing, OutgoingsSettings } from '../types'
import type { OmitKeysStartingWith } from '~/types/helpers'
import type { RepeaterItem } from '@manager'

export type YearModel = {
  Year: number
  StartDate: string
  EndDate: string
  Dates: string
  Days: number
  status: 'past' | 'present' | 'future'
}

export function generateOutgoingsTableData(
  initialValue: MaybeRef<Outgoing[]>,
  settings: Ref<OutgoingsSettings>,
) {
  const {
    items: data,
    filteredItems: filteredData,
    add,
    update,
    remove,
  } = useRepeater<Outgoing & YearModel>(
    initialValue as MaybeRef<(Outgoing & YearModel)[]>,
    {
      scrollAndBlink: false,
    },
  )

  const leaseStartDate = dayjs(settings.value.LeaseStartDate)
  const leaseEndDate = dayjs(settings.value.LeaseEndDate)

  const financialYearDate = computed(() => {
    switch (settings.value.OutgoingYearType) {
      case OutgoingYearType.FINANCIAL_YEAR_END:
        return settings.value.FinancialYearEnd
      case OutgoingYearType.OTHER_YEAR_END:
        return settings.value.OtherYearEnd
    }
  })

  watch(
    () => [settings.value.OutgoingYearType, financialYearDate.value],
    () => {
      let currentStartDate = leaseStartDate

      let yearNumber = 1
      const maxIterations = 51

      while (
        currentStartDate.isBefore(leaseEndDate) &&
        yearNumber < maxIterations
      ) {
        const currentEndDate = calculateEndDateByYearType(
          settings.value.OutgoingYearType,
          currentStartDate,
          leaseEndDate,
          financialYearDate.value,
        )

        if (!currentEndDate) {
          return
        }

        addOrUpdate(yearNumber, {
          Year: yearNumber,
          Dates: currentStartDate.toISOString(),
          StartDate: currentStartDate.format('DD MMM YYYY'),
          EndDate: currentEndDate.format('DD MMM YYYY'),
          Days: getDaysDiff(currentStartDate, currentEndDate),
          status: getStatus(currentStartDate, currentEndDate),
        })

        // Update the start date for the next year
        currentStartDate =
          settings.value.OutgoingYearType === OutgoingYearType.CALENDAR_YEAR
            ? currentStartDate.add(1, 'year').startOf('year')
            : currentEndDate.add(1, 'day')
        yearNumber++
      }

      // Remove items that are not in the generated years
      removeItems(currentStartDate, yearNumber)
    },
    { immediate: true },
  )

  return { data: filteredData }

  function addOrUpdate(yearNumber: number, newData: YearModel) {
    let item = data.value[yearNumber - 1]

    if (item) {
      // Update existing item
      update(item.__id, {
        Year: newData.Year,
        Dates: newData.Dates,
      })
      item.__deleted = false
    } else {
      // Add new item
      item = {
        objectReferenceId: '0',
        Year: newData.Year,
        Dates: newData.Dates,
        Budget: settings.value.InitialBudget,
        Actual: undefined,
        Variance: undefined,
        PayableDate: '',
      } as RepeaterItem<Outgoing & YearModel>
      add(item)
    }

    Object.defineProperties(item, {
      StartDate: {
        value: newData.StartDate,
        enumerable: false,
        writable: true,
        configurable: true,
      },
      EndDate: {
        value: newData.EndDate,
        enumerable: false,
        writable: true,
        configurable: true,
      },
      status: {
        value: newData.status,
        enumerable: false,
        writable: true,
        configurable: true,
      },
      calculated_Year: {
        get: function () {
          return this.Year ?? undefined
        },
        enumerable: false,
        configurable: true,
      },
      calculated_Dates: {
        value: newData.Days,
        enumerable: false,
        writable: true,
        configurable: true,
      },
      calculated_Budget: {
        get: function () {
          return this.Budget ?? undefined
        },
        enumerable: false,
        configurable: true,
      },
      calculated_Actual: {
        get: function () {
          return this.Actual ?? undefined
        },
        enumerable: false,
        configurable: true,
      },
      calculated_Variance: {
        get: function () {
          return this.Variance ?? undefined
        },
        enumerable: false,
        configurable: true,
      },
      calculated_PayableDate: {
        get: function () {
          return this.PayableDate ?? undefined
        },
        enumerable: false,
        configurable: true,
      },
    })
  }

  function removeItems(currentStartDate: dayjs.Dayjs, yearNumber: number) {
    if (currentStartDate.isSameOrAfter(leaseEndDate)) {
      for (let i = data.value.length - 1; i >= 0; i--) {
        const item = data.value[i]
        if (i + 1 >= yearNumber) {
          remove(item.__id)
        }
      }
    }
  }
}

/* === YEAR CALCULATION ===*/
function calculateEndDateByYearType(
  yearType: OutgoingYearType,
  currentStartDate: Dayjs,
  leaseEndDate: Dayjs,
  financialYearDate?: string,
) {
  switch (yearType) {
    case OutgoingYearType.LEASE_YEAR: {
      return calculateLeaseYear(currentStartDate)
    }
    case OutgoingYearType.CALENDAR_YEAR: {
      return calculateCalendarYear(currentStartDate, leaseEndDate)
    }
    case OutgoingYearType.FINANCIAL_YEAR_END: {
      return calculateFinancialYear(
        currentStartDate,
        leaseEndDate,
        financialYearDate,
      )
    }
    // Add more cases for additional types if needed
    default: {
      return calculateFinancialYear(
        currentStartDate,
        leaseEndDate,
        financialYearDate,
      )
    }
  }
}

function calculateLeaseYear(currentStartDate: Dayjs) {
  return currentStartDate.add(1, 'year').subtract(1, 'day')
}

function calculateCalendarYear(currentStartDate: Dayjs, leaseEndDate: Dayjs) {
  return currentStartDate.endOf('year').isBefore(leaseEndDate)
    ? currentStartDate.endOf('year')
    : leaseEndDate
}

function calculateFinancialYear(
  currentStartDate: Dayjs,
  leaseEndDate: Dayjs,
  financialYearDate?: string,
) {
  if (!financialYearDate) {
    return
  }

  let endDate = dayjs(financialYearDate, 'MM-DD').set(
    'year',
    currentStartDate.year(),
  )

  if (currentStartDate.isSameOrAfter(endDate)) {
    endDate = endDate.add(1, 'year')
  }

  return endDate.isBefore(leaseEndDate) ? endDate : leaseEndDate
}

/* === YEAR CALCULATION ===*/

function getStatus(startDate: Dayjs, endDate: Dayjs) {
  const today = dayjs()
  let status: YearModel['status'] = 'future'

  if (today.isAfter(endDate)) {
    status = 'past'
  } else if (
    (today.isSame(startDate) || today.isAfter(startDate)) &&
    (today.isSame(endDate) || today.isBefore(endDate))
  ) {
    status = 'present'
  }

  return status
}

function getDaysDiff(startDate: Dayjs, endDate: Dayjs) {
  return endDate.diff(startDate, 'day') + 1
}
