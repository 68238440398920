<script setup lang="ts">
import { createColumnHelper } from '@tanstack/vue-table'
import {
  type TanstackTableColumn,
  useTanstackTableVariants,
} from '@ui/components/TanstackTable'

import type { Ref, WritableComputedRef } from 'vue'

import {
  generateOutgoingsTableData,
  type YearModel,
} from './composables/yearGenerators'

import {
  type IManagerFormNodeValue,
  type INodeField,
  type INodeObject,
  joinPath,
  parseSizeToGrid,
  useFieldCast,
  useFormula,
  useObjectVModel,
  // useRepeater,
} from '@manager'

import type {
  IManagerTableEmits,
  IManagerTableProps,
} from '@manager/components/Group/Table/types'
import type { Outgoing, OutgoingsSettings } from './types'
import type { FormKitNode } from '@formkit/core'
import { OutgoingMethodology } from '@manager/components/Group/Custom/OutgoingsTable/constants'
import { isNullish } from '@morev/utils'

type OutgoingWithYears = Outgoing & {
  StartDate: YearModel['StartDate']
  EndDate: YearModel['EndDate']
  status: YearModel['status']
}

const props = withDefaults(defineProps<IManagerTableProps>(), {
  parentValue: () => ({}),
  parentPath: '',
})

const dayjs = useDayjs()
const emit = defineEmits<IManagerTableEmits>()

const colSize = computed(() => parseSizeToGrid(props.node.size))

const objectNode = props.node.nodes[0] as INodeObject
const objectPath = computed(() => joinPath(props.parentPath, objectNode.name))
const joinDataPath = (index: number) =>
  joinPath(objectPath.value, `data[${index}]`)

const [_, dataModelValue] = useObjectVModel(
  {
    ...props,
    node: objectNode,
  },
  'parentValue',
  emit,
) as unknown as readonly [
  Ref<IManagerFormNodeValue>,
  WritableComputedRef<OutgoingWithYears[]>,
]

// const { filteredItems } = useRepeater(dataModelValue)

const { formValue } = useManagerFormValue()

const settings = computed<OutgoingsSettings>(
  () => (formValue?.value?.OutgoingsSettings?.data as any)?.[0],
)

const annualIncrease = computed(() => settings.value.SetAnnualIncrease)

const { data } = generateOutgoingsTableData(dataModelValue, settings)

const cache = new WeakMap<
  INodeField,
  { formula: Ref<any>[]; castPlugin: (node: FormKitNode) => void }
>()

function getInitialBudgetByMethodology(methodology: OutgoingMethodology) {
  switch (methodology) {
    case OutgoingMethodology.INITIAL_BUDGET: {
      return 'OutgoingsSettings.data[0].InitialBudget'
    }
    case OutgoingMethodology.DOLLAR_PER_SQM: {
      return 'OutgoingsSettings.data[0].DollarPerSqm.InitialBudget'
    }
    case OutgoingMethodology.PERCENTAGE: {
      return 'OutgoingsSettings.data[0].Percentage.InitialBudget'
    }
  }
}

const initializeBudgetFormula = (node: INodeField, index: number) => {
  let formula: string
  if (index === 0) {
    formula = getInitialBudgetByMethodology(settings.value.OutgoingMethodology)
  } else {
    const previousBudget = joinPath(joinDataPath(index - 1), 'Budget')
    formula = `${previousBudget} + (${previousBudget} * OutgoingsSettings.data[0].ByPercentage)`
  }

  const { evaluated } = useFormula({ ...node, formula }, joinDataPath(index))
  cache.get(node)!.formula[index]
  return evaluated
}

const initializeVarianceFormula = (node: INodeField, index: number) => {
  const { evaluated } = useFormula(node, joinDataPath(index))
  cache.get(node)!.formula.push(evaluated)
  return evaluated
}

let budgetNode: INodeField

const columnHelper = createColumnHelper<OutgoingWithYears>()
const columns = computed(() => {
  const columns = objectNode.nodes.map((node) => {
    if (!budgetNode && node.name === 'Budget') {
      budgetNode = node as INodeField
    }

    return columnHelper.accessor(node.name as keyof Outgoing, {
      header: node.label ?? undefined,
      meta: {
        slotProps: {
          node,
        },
      },
      cell: ({ getValue, row }) => {
        const value = getValue()

        if (!cache.has(node as INodeField)) {
          const { castPlugin } = useFieldCast(node as INodeField)
          cache.set(node as INodeField, { formula: [], castPlugin })
        }

        if (
          node.name === 'Budget' &&
          (annualIncrease.value || row.index === 0)
        ) {
          const evaluated =
            cache.get(node as INodeField)!.formula[row.index] ??
            initializeBudgetFormula(node as INodeField, row.index)

          data.value[row.index]['Budget'] = evaluated.value || undefined
          return evaluated.value
        } else if (node.name === 'Variance') {
          const evaluated =
            cache.get(node as INodeField)!.formula[row.index] ??
            initializeVarianceFormula(node as INodeField, row.index)

          data.value[row.index]['Variance'] = evaluated.value || undefined
          return evaluated.value
        }

        return value
      },
    })
  })

  return [...columns]
})

watch(
  () => settings.value.OutgoingMethodology,
  () => {
    if (budgetNode && cache.has(budgetNode)) {
      initializeBudgetFormula(budgetNode, 0)
    }
  },
)

const variant = useTanstackTableVariants({
  variants: {
    status: {
      present: {
        tbodyTr: '!bg-white/5',
      },
    },
  },
})

const ownershipTooltip = (value: number, prefix: string) => {
  if (value > 0) {
    return `You owe the lessor ${prefix}${value}`
  }
  if (value < 0) {
    return `Lessor owns you ${prefix}${String(value).replace('-', '')}`
  }
  if (value === 0) {
    return `You and lessor are even`
  }
}
</script>

<template>
  <div :class="[colSize, 'grid grid-flow-row grid-cols-1 gap-2']">
    <template v-if="!settings.OutgoingYearType">
      <div v-if="node.label" class="text-white text-base">
        {{ node.label }}
      </div>

      <div
        class="bg-warning/5 border-warning/70 text-warning w-fit rounded-lg border-2 px-8 py-4"
      >
        Fill all information to check the outgoings table.
      </div>
    </template>

    <div v-else>
      <div
        class="flex w-full mb-2 items-center"
        :class="[node.label ? 'justify-between' : 'justify-end']"
      >
        <span v-if="node.label" class="text-white text-base">
          {{ node.label }}
        </span>
        <span class="text-xs px-2">
          <time>
            {{
              dayjs(settings.LeaseStartDate, 'YYYY-MM-DD').format('DD MMM YYYY')
            }}
          </time>
          -
          <time>
            {{
              dayjs(settings.LeaseEndDate, 'YYYY-MM-DD').format('DD MMM YYYY')
            }}
          </time>
        </span>
      </div>

      <!-- TODO: Tanstack table cache is not updating, so we need to display the items (hidden) to force the cache to update -->
      <div class="hidden">
        {{ data }}
      </div>

      <TanstackTable
        class="border-gray-650 outgoings-table border"
        :columns="columns"
        :data="[...data]"
        :sticky="{ scroller: null }"
        rounded
        :variant="variant"
        :class-tbody-tr="(row) => ({ status: row.original.status })"
      >
        <!-- Empty -->
        <template #empty>
          <div
            class="flex items-center justify-center px-4 py-3 text-sm text-gray-500"
          >
            <span>
              {{ node.emptyMessage ?? "You haven't added items yet." }}
            </span>
          </div>
        </template>

        <!-- Year -->
        <template #item-Year="{ item }: TanstackTableColumn<OutgoingWithYears>">
          <span
            class="text font-lg flex w-full justify-center font-bold"
            :class="{
              'text-success': item.status === 'present',
              'text-success/30': item.status === 'past',
              'text-gray-400': item.status === 'future',
            }"
          >
            {{ item.Year }}
          </span>
        </template>

        <!-- Dates -->
        <template
          #item-Dates="{ item }: TanstackTableColumn<OutgoingWithYears>"
        >
          <Tooltip
            :content="`${item.calculated_Dates} days`"
            class="bg-gray-900 text-xs"
            placement="top"
          >
            <div class="flex min-w-max items-center gap-2 whitespace-nowrap">
              <time class="text-gray-100">
                {{ item.StartDate }}
              </time>
              <span class="text-gray-600"> - </span>
              <time class="text-gray-100">
                {{ item.EndDate }}
              </time>
            </div>
          </Tooltip>
        </template>

        <!-- Budget -->
        <template
          #item-Budget="{
            id,
            index,
            value,
            node,
          }: TanstackTableColumn<OutgoingWithYears>"
        >
          <!-- Budget (only for annual increase) -->
          <template v-if="annualIncrease || index === 0">
            <div class="w-52">
              <CurrencyDisplay
                :value="value"
                :prefix="node.prefix"
                :suffix="node.suffix"
              />
            </div>
          </template>
          <!-- Budget (for the rest) -->
          <template v-else>
            <div class="flex h-full w-full">
              <FormKit
                v-if="data[index]"
                v-model="data[index][id]"
                outer-class="w-52"
                inner-class=" group-hover/tr:border-primary/70 group-hover/tr:ring-primary/20 group-hover/tr:border group-hover/tr:ring-4 group-hover/tr:[&>span:first-child]:text-gray-100"
                type="currency"
                currency="AUD"
                display-locale="en-AU"
                :plugins="cache.has(node) ? [cache.get(node)!.castPlugin] : []"
                min="0"
                max="10000000"
                decimals="2"
                validation="number|min:0|max:10000000"
                :name="id"
              >
                <template v-if="node.suffix" #suffix>
                  <span
                    class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
                  >
                    {{ node.suffix }}
                  </span>
                </template>
              </FormKit>
            </div>
          </template>
        </template>

        <!-- Actual -->
        <template
          #item-Actual="{
            id,
            index,
            node,
          }: TanstackTableColumn<OutgoingWithYears>"
        >
          <div class="flex h-full w-full">
            <FormKit
              v-if="data[index]"
              v-model="data[index][id]"
              :name="id"
              type="currency"
              currency="AUD"
              display-locale="en-AU"
              :plugins="cache.has(node) ? [cache.get(node)!.castPlugin] : []"
              min="0"
              max="10000000"
              decimals="2"
              validation="number|min:0|max:10000000"
              outer-class="max-w-52"
              inner-class=" group-hover/tr:border-primary/70 group-hover/tr:ring-primary/20 group-hover/tr:border group-hover/tr:ring-4 group-hover/tr:[&>span:first-child]:text-gray-100"
            >
              <template v-if="node.suffix" #suffix>
                <span
                  class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
                >
                  {{ node.suffix }}
                </span>
              </template>
            </FormKit>
          </div>
        </template>

        <!-- Variance -->
        <template
          #item-Variance="{
            node,
            value,
            index,
          }: TanstackTableColumn<OutgoingWithYears>"
        >
          <CurrencyDisplay
            :value="value"
            :prefix="node.prefix"
            :tooltip="
              isNullish(data[index]['Actual']) ||
              isNullish(data[index]['Budget'])
                ? 'Fill budget to check the value'
                : ownershipTooltip(value, node.prefix)
            "
            show-sign
            invert-sign-color
          />
        </template>

        <!-- Payable Date -->
        <template
          #item-PayableDate="{
            id,
            index,
            node,
          }: TanstackTableColumn<OutgoingWithYears>"
        >
          <FormKit
            v-if="data[index]"
            v-model="data[index][id]"
            inner-class=" group-hover/tr:border-primary/70 group-hover/tr:ring-primary/20 group-hover/tr:border group-hover/tr:ring-4 group-hover/tr:[&>span:first-child]:text-gray-100"
            type="datepicker"
            :name="id"
            placeholder="Date"
            :plugins="cache.has(node) ? [cache.get(node)!.castPlugin] : []"
            outer-class="w-32"
          >
            <template v-if="node.prefix" #prefix>
              <span
                class="flex h-10 items-center rounded-l-[5px] bg-gray-700 px-2.5 pt-px text-xs"
              >
                {{ node.prefix }}
              </span>
            </template>
            <template v-if="node.suffix" #suffix>
              <span
                class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
              >
                {{ node.suffix }}
              </span>
            </template>
          </FormKit>
        </template>
      </TanstackTable>
    </div>
  </div>
</template>
